import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"


const Footer = () => (

    <footer className="footer-center-logo bg-light-gray padding-five-tb sm-padding-30px-tb">
        <div className="container">
            <div className="row align-items-center">
                <div className="col-lg-4 col-md-5 text-small text-center alt-font sm-margin-15px-bottom">
                    <h6 className="fs-5">Salalah Petroleum Company</h6>
                    <p className="text-muted">Salalah Free Zone, Salalah, Sultanate of Oman</p>
                </div>
                <div className="col-lg-4 col-md-2 text-center sm-margin-10px-bottom">
                    <Link to="/">
                        <StaticImage
                            src="../images/logo.png"
                            formats={["auto", "webp", "avif"]}
                            alt=""
                            className="img-fluid w-25"
                        />
                    </Link>
                </div>
                <div className="col-lg-4 col-md-5 text-center">
                    <span className="alt-font text-small margin-20px-right md-margin-15px-right">On social networks</span>
                    <div className="social-icon-style-8 d-inline-block align-middle">
                        <ul className="small-icon mb-0">
                            <li>
                                <a href="https://wa.me/96879101690" target="_blank" rel="noreferrer">
                                    <i className="fab fa-whatsapp"></i>
                                </a>
                            </li>

                            <li>
                                <a href="https://www.linkedin.com/company/salalah-petroleum-co/" target="_blank" rel="noreferrer">
                                    <i className="fab fa-linkedin"></i>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </footer>

)

export default Footer
