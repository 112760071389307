import * as React from "react"

import Layout from "../../components/layout"
import Seo from "../../components/seo"
import Footer from "../../components/footer"
import { StaticImage } from "gatsby-plugin-image"

export default class Thinner extends React.Component {

  render() {

    return (

      <Layout>

        <Seo title="SPCO" lang="en" />

        <section className="wow animate__fadeIn">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-12 col-lg-5 col-md-6 text-center md-margin-30px-bottom wow animate__fadeInLeft">
                <StaticImage
                  src="../../images/s6.jpg"
                  formats={["auto", "webp", "avif"]}
                  alt=""
                  className="border-radius-6 w-100"
                />
              </div>
              <div className="col-12 col-lg-7 col-md-6 text-center text-md-start padding-eight-lr lg-padding-six-lr md-padding-15px-lr wow animate__fadeInRight" data-wow-delay="0.2s">

                <span className="text-deep-pink alt-font margin-10px-bottom d-inline-block text-medium">Thinner</span>

                <p>Thinners are volatile chemical liquids that are used in diluting paints. These liquids can improve color properties. These materials have a crucial role in color properties, and the time of drying. A right selection of thinner impacts viscosity, cutting properties, separability, drying time, and other properties.</p>
                <strong>The uses of thinner</strong>
                <p>Thinners can be used in cleaning tools and equipment.  In order to decrease the viscosity of thinners, these materials are combined with paints to use in paint applicators. Another use of thinner is that it prevents paints from drying after their door is opened. </p>

                <a className="btn btn-primary me-2" href="/files/Thinner-20000.pdf">Download Thinner-20000</a>
                <a className="btn btn-primary" href="/files/Thinner-A95.pdf">Download Thinner-A95</a>

              </div>
            </div>
          </div>
        </section>

        <Footer />

      </Layout>

    )

  }

}